<template>
    <div class="container">
        <router-link to="/">
            <div :class="{active:state.activeIndex==1}">
                <img :src="(state.activeIndex==1) ? state.icon[1] :state.icon[0]" alt="">
                <span>创作</span>
            </div>
        </router-link>
        <router-link to="/task">
            <div :class="{active:state.activeIndex==2}">
                <img :src="(state.activeIndex==2) ? state.icon[6] :state.icon[7]" alt="">
                <span>任务</span>
            </div>
        </router-link>
        <router-link to="/pickPicture">
            <div :class="{active:state.activeIndex==3}">
                <img :src="(state.activeIndex==3) ? state.icon[3] :state.icon[2]" alt="">
                <span>选片</span>
            </div>
        </router-link>
        <router-link to="/my">
            <div :class="{active:state.activeIndex==0}">
                <img :src="(state.activeIndex==0) ? state.icon[5] :state.icon[4]" alt="">
                <span>我的</span>
            </div>
        </router-link>
    </div>
</template>
<script>
import { onMounted, onUnmounted, reactive, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import img1 from '../assets/星星棒.svg'
import img2 from '../assets/蓝星星棒.svg'
import img3 from '../assets/选片.svg'
import img4 from '../assets/蓝选片.svg'
import img5 from '../assets/我的.svg'
import img6 from '../assets/蓝我的.svg'
import img7 from '../assets/蓝时钟.png'
import img8 from '../assets/灰时钟.png'

export default {
    setup() {
        const state = reactive({
            count: 1,
            icon: [img1, img2, img3, img4, img5, img6,img7,img8],
            activeIndex: 1,
        });

        const route = useRoute();


        watchEffect(() => {
            const path = route.path;
            switch (path) {
                case '/':
                    state.activeIndex = 1;
                    break;
                case '/pickPicture':
                    state.activeIndex = 3;
                    break;
                case '/task':
                    state.activeIndex = 2;
                    break;
                default:
                    state.activeIndex = 0;
                    break;
            }
        });
        return { state };
    },
};
</script>
<style scoped lang="less">
.container {
    height: 55px;
    background-color: #191b1e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;


    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 75px;
        height: 55px;
        background-color: #191B1E;

        img {
            width: 19px;
            margin-bottom: 3px;
        }

        span {
            color: #999999;
            font-size: 12px;
        }
    }
    div.active{
        background-color: #343A40;
        span {
            color: #31ECEC;
            font-size: 12px;
        }

    }


}
</style>
