<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/> -->
  <router-view />

  <div class="tab">
    <div class="div">

    </div>
    <div class="tab-bar">
      <TabBar />
    </div>
  </div>
</template>
<script>
import CreateView from './views/CreateView.vue';
import TabBar from './components/TabBar.vue';
import PickPictureView from './views/PickPictureView.vue';

export default {
  components: {
    CreateView,
    TabBar,
    PickPictureView
  },

}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}

.div {
  padding-bottom: 50px;
  background-color: #131317;
}

.tab-bar {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 999;
}
</style>

