<template>
    <div class="top1">
        浏览选片
    </div>
    <div class="container1">
        <div class="btn">
            <div class="b1" :class="{ active: state.recent }" @click="state.recent = true; state.save = false; sendMysave()">
                <span>最近创作</span>
            </div>
            <div class="b1" :class="{ active: state.save }" @click="state.save = true; state.recent = false; sendMysave()">
                <span>我的收藏</span>
            </div>
        </div>
        <div class="current">
            <span>当前选中：{{ state.selectedImg }}</span>
            <span v-if="state.recent">{{findIndexByName(state.artwork,state.selectedImg)+1}}/{{ state.artwork.length }}</span>
            <span v-else>{{findIndexByName(myCollection,state.selectedImg)+1}}/{{ myCollection.length }}</span>
        </div>
        <div class="swipe-wrapper">
            <van-swipe :autoplay="0" :initial-swipe="0" :show-indicators="true" v-on:touchmove="swipeMove" ref="swipe">
                <van-swipe-item v-for="(group, index) in currentArtWork" :key="index" class="swipe-item">
                    <van-grid :column-num="3" class="grid-container" :border="false">
                        <van-grid-item v-for="item in group" :key="item.id" icon="photo-o" :text="item.name"
                            class="grid-item" @click="selectImg(item.name); sendSelectImg()">
                            <img :src="item.src" alt="" class="grid-image"
                                :class="{ 'grid-image-selected': item.name === state.selectedImg }">
                            <img v-if="isArtworkExists(myCollection, item.name) && state.recent" class="love"
                                :src="state.love" alt="">
                            <span>{{ item.name }}</span>
                        </van-grid-item>
                    </van-grid>
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="save" @click="addToCollection(state.selectedImg)">
            <img v-if="!isArtworkExists(myCollection, state.selectedImg)" :src="state.nolove" alt="">
            <img v-else :src="state.love" alt="">
            <span>收藏</span>
        </div>
    </div>
</template>
<script>
import { onMounted, onUnmounted, reactive, computed, ref } from 'vue';
import { io } from 'socket.io-client';
import img1 from '../assets/artwork/u55.png';
import img2 from '../assets/artwork/u57.svg';
import img3 from '../assets/artwork/u60.svg';
import img4 from '../assets/artwork/u61.svg';
import img5 from '../assets/artwork/u1534.svg';
import img6 from '../assets/artwork/u1538.svg';
import img7 from '../assets/artwork/u1545.svg';
import img8 from '../assets/artwork/u1549.svg';
import img9 from '../assets/artwork/u1552.svg';
import img0 from '../assets/artwork/u1559.svg';
import img11 from '../assets/artwork/u1563.svg';
import love from '../assets/artwork/蓝爱心.svg';
import nolove from '../assets/artwork/灰爱心.svg';

export default {
    setup() {
        const state = reactive({
            count: 1,
            recent: true,
            save: false,
            imgCard: {
                img_name: 'image_12',
                style_tag: ['朋克', '机械', '战甲'],
                model: '萌羽儿',
                version: 'v1.3',
                prompt: '高细节，极其精美美丽，美女，睁大眼睛，灰发，莫西干头，太阳镜，海军陆战队服，七分身镜头，教室，泡泡龙',
                smart_analysis: '图片内容合规，人物四肢结构准确，体态自然无畸形...'
            },
            artwork: [
                { id: 0, name: 'img_0', src: img0 },
                { id: 1, name: 'img_1', src: img2 },
                { id: 2, name: 'img_2', src: img3 },
                { id: 3, name: 'img_3', src: img4 },
                { id: 4, name: 'img_4', src: img9 },
                { id: 5, name: 'img_5', src: img6 },
                { id: 6, name: 'img_6', src: img7 },
                { id: 7, name: 'img_7', src: img8 },
                { id: 8, name: 'img_8', src: img1 },
                { id: 8, name: 'img_9', src: img1 },
                { id: 9, name: 'img_10', src: img2 },
                { id: 10, name: 'img_11', src: img3 },
                { id: 11, name: 'img_12', src: img4 },
                { id: 12, name: 'img_13', src: img9 },
                { id: 13, name: 'img_14', src: img6 },
                { id: 14, name: 'img_15', src: img8 },
                { id: 15, name: 'img_16', src: img1 },
                { id: 16, name: 'img_17', src: img1 },
                { id: 17, name: 'img_18', src: img3 },
                { id: 18, name: 'img_19', src: img4 },
                { id: 19, name: 'img_20', src: img9 },
                { id: 20, name: 'img_21', src: img6 },
                { id: 21, name: 'img_22', src: img8 },
                { id: 22, name: 'img_23', src: img1 },
                { id: 23, name: 'img_24', src: img1 },
                { id: 24, name: 'img_25', src: img3 },
                { id: 25, name: 'img_26', src: img4 },
                // { id: 26, name: 'img_27', src: img9 },
                // { id: 27, name: 'img_28', src: img6 },
            ],

            selectedImg: 'img_0',
            scrollPosition: 0,
            love: love,
            nolove: nolove,
            groupSize: 9,
            currentGroup: 0

        });
        const myCollection = ref([]);

        const addToCollection = (name) => {
            const selectedArtwork = state.artwork.find(art => art.name === name);
            const index = myCollection.value.findIndex(art => art.name === name);
            if (index > -1) {
                myCollection.value.splice(index, 1);
            } else {
                myCollection.value.push(selectedArtwork);
            }
            chunkedMyCollection.value = myCollection.value;
            calculateChunkedArtwork();
            console.log(myCollection.value)
        }
        const isArtworkExists = (artwork, name) => {
            return artwork.some(art => art.name === name);
        }
        const findIndexByName = (arr, name) => {
            return arr.findIndex(item => item.name === name);
        };
        const currentArtWork = computed(() => state.recent ? chunkedArtwork.value : chunkedMyCollection.value);

        const selectImg = (name) => {
            state.selectedImg = name;
            // state.scrollPosition = currentArtWork.value.findIndex(item => item.name === name);
            // console.log(state.scrollPosition)
            let flattenedArray = [].concat(...currentArtWork.value);
            let index = flattenedArray.findIndex(item => item.name === name);
            state.scrollPosition = index;
            console.log(state.scrollPosition);
        }
        const swipe = ref(null);

        const chunkedArtwork = ref([]);
        const chunkedMyCollection = ref([]);


        const calculateChunkedArtwork = () => {
            const chunkSize = 9;
            const result = [];
            const result2 = [];
            for (let i = 0; i < state.artwork.length; i += chunkSize) {
                result.push(state.artwork.slice(i, i + chunkSize));
            }
            for (let i = 0; i < chunkedMyCollection.value.length; i += chunkSize) {
                result2.push(chunkedMyCollection.value.slice(i, i + chunkSize));
            }
            chunkedArtwork.value = result;
            chunkedMyCollection.value = result2;
        };


        const socket = io('http://localhost:3000');
        const sendSelectImg = () => {
            console.log('向服务器发送消息：切换到已选中模型');
            socket.emit('message', 'selectImg', {
                scrollPosition: state.scrollPosition,
            });
        };
        const sendMysave = () => {
            console.log('向服务器发送消息：切换到已选中模型');
            socket.emit('message', 'saveBtn', {
                recent: state.recent,
                save: state.save,
                myCollection: myCollection.value
            });
        };
        onMounted(() => {
            calculateChunkedArtwork();
            socket.on('connect', () => {
                console.log('已连接到 Socket 服务器');
                socket.emit('message', 'Hello,我是展示界面');
            });
            socket.on('response', (data) => {
                console.log('收到服务器消息:', data);
            });
        });
        const swipeMove = (event) => {
            const deltaX = event.touches[0].clientX - event.touches[0].startX;
            if (deltaX > 30) {
                swipe.value.swipeTo(0);
            } else if (deltaX < -30) {
                swipe.value.swipeTo(1);
            }
        };
        onUnmounted(() => {
            socket.close();
        })
        return {
            state, chunkedArtwork,
            swipeMove,
            currentArtWork,
            selectImg,
            sendSelectImg,
            addToCollection,
            myCollection,
            isArtworkExists,
            chunkedMyCollection,
            sendMysave,
            findIndexByName
        };
    },
};
</script>
<style   lang="less">
.top1 {
    height: 40px;
    background-color: #16161A;
    color: #FFFFFF;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-bottom: 1px solid ;
}

.container1 {
    background-color: #131317;
    height: 590px;

    .btn {
        display: flex;
        padding-top: 10px;
        justify-content: center;

        .b1 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 175px;
            height: 40px;
            background-color: #000000A5;
            color: #F2F2F2A5;
            font-size: 14px;
            line-height: 40px;
            text-align: center;
            border-radius: 4px;

            span {
                margin-left: 5px;
            }
        }

        .b1.active span {
            color: #F2F2F2;
        }

        .b1.active {
            background-color: #343A40;
        }
    }

    .current {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 12px;
        margin-right: 12px;
        margin-top: 17px;

        span {
            font-size: 14px;
            color: rgba(242, 242, 242, 0.933);
        }
    }

    .swipe-wrapper {
        margin-left: 2px;
        margin-right: 2px;
        overflow: hidden;
        height: 400px;

        .van-swipe {
            position: relative;
            padding-bottom: 20px;

            .van-swipe__indicators {
                position: absolute;
                width: 254px;
                height: 4px;
                // background-color: rgba(255, 255, 255, 0.45);
                border-radius: 4px;
                bottom: 0px;

                .van-swipe__indicator {
                    width: 110px;
                    height: 4px;
                    background-color: rgba(255, 255, 255, 0.45);
                    border-radius: 88px;
                }

            }
        }




    }

    .grid-container {
        text-align: center;
        border: none;

    }

    .grid-item div {
        background-color: #131317;
        border: none;
        padding: 0%;

        span {
            margin-top: 4px;
            font-size: 12px;
            color: rgba(242, 242, 242, 0.647);
        }
    }

    .grid-image {
        border: 3px solid transparent;
        width: 112px;
        height: 64px;
        border-radius: 5px;
        margin-top: 15px;
        position: relative;
    }

    .love {
        position: absolute;
        width: 13px;
        height: 10px;
        top: 22px;
        right: 9px;
    }

    .grid-image-selected {
        border: 3px solid #35ECED;
    }

    .save {
        margin: -15px auto;
        width: 169px;
        height: 48px;
        background-color: #303235;
        border: 1px solid rgb(74, 74, 74);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 27px;
            height: 21px;
            margin-right: 5px;
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(242, 242, 242, 0.847);
            line-height: normal;
        }
    }

}
</style>
