<template>
    <div class="top">
        <img :src="state.icon[0]" alt="">
        <span>创作中心</span>
        <img :src="state.icon[1]" alt="">
    </div>
    <div v-if="isUsernameInputVisible" class="username-input">
        <span>用户名</span>
        <input v-model="inputUsername" @input="handleInput" placeholder="2~16字符，不含空格等特殊字符" />
        <button :disabled="usernameError||inputUsername==''" @click="submitUsername(); console.log(isUsernameInputVisible)">确定</button>
        <p v-if="usernameError" class="error-msg">用户名不符合要求</p>
    </div>
    <div class="container">
        <div class="btn">
            <div class="b1" :class="{ active: state.tti }" @click="state.tti = true; state.iti = false; detail()">
                <img v-if="state.tti" :src="state.icon[4]" alt="">
                <img v-else :src="state.icon[4]" style="opacity: 0.65;" alt="">
                <span>文生图</span>
            </div>
            <div class="b1" :class="{ active: state.iti }" @click="state.iti = true; state.tti = false; detail()">
                <img v-if="state.iti" :src="state.icon[9]" alt="">
                <img v-else style="opacity: 0.65;" :src="state.icon[9]" alt="">
                <span>图生图</span>
            </div>
        </div>
        <div class="upload_image" v-if="state.iti">
            <span class="s1">上传图片</span>
            <div class="upload-box">
                <img v-if="!previewImage" class="plus-icon" :src="state.icon[7]" @click="openFileInput" alt="">
                <div v-if="!previewImage" class="upload-text">{{ uploadText }}</div>
                <div v-if="!previewImage" class="upload-text2">支持图片格式：JPG、PNG，不超过10MB</div>
                <input type="file" ref="fileInputRef" @change="handleFileUpload" hidden>
                <div class="preview">
                    <img v-if="previewImage" :src="previewImage" alt="预览图片">
                    <button v-if="previewImage" class="close-button" @click="clearPreview">重新选择</button>
                </div>
            </div>
        </div>
        <div class="create">
            <div class="promit">
                <span class="s1">提示词</span>
                <div>
                    <img @click="incantation" :src="state.icon[6]" alt="">
                    <span @click="incantation" class="s2">随机咒语</span>
                </div>
            </div>
            <!-- <input class="text" type="text" name="" id="" placeholder="请输入提示词，使用回车键换行支持同时输出多组提示词"> -->
            <textarea class="text" v-model="inputText" name="" id="" cols="30" rows="10"
                placeholder="请输入提示词，使用回车键换行支持同时输出多组提示词"></textarea>
            <div class="create_now" @click="create">
                <span class="s1">立即创作</span>
                <span class="s2">已选精灵{{ state.count }}个</span>
            </div>
        </div>
        <div class="model">
            <span>定制精灵</span>
            <div class="button">
                <div class="btn">
                    <div class="all" :class="{ active: state.all }"
                        @click="state.all = true; state.selected = false; selected()">全部
                    </div>
                    <div class="selected" :class="{ active: state.selected, no_click: !state.clickable }"
                        @click="state.selected = true; state.all = false; selected()"
                        :style="{ 'pointer-events': state.clickable ? 'auto' : 'none' }">
                        <span class="s1">已选</span>
                        <span class="s2" v-show="state.count != 0">{{ state.count }}</span>
                    </div>
                </div>
                <div class="choose">
                    <span @click="handleChoise" class="choise" :style="{ 'pointer-events': state.all ? 'auto' : 'none' }">
                        {{ state.choiseText }}</span>
                    <span class="choise1" v-if="!state.clickable">({{ state.count }})</span>
                </div>

            </div>
            <div class="model_box" id="model_box">
                <div class="row" v-for="(row, rowIndex) in (state.all ? state.allModels : state.selectedModels)"
                    :key="rowIndex">
                    <div v-for="(img, index) in row" :key="index"
                        @click="handleSelectModel(img.name, rowIndex, index); send(rowIndex, index)">
                        <img :src="img.src" alt="" :class="{ selected: img.name == state.selectedModel }">
                        <div class="over">{{ img.name }}</div>
                        <img v-if="!state.clickable && !checkIfSelected(img.name)" class="over2" :src="state.icon[8]"
                            alt="">
                        <div v-if="!state.clickable && checkIfSelected(img.name)" class="over3">{{
                            findElementPosition(img.name) + 1 }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn2">
            <div class="detail" :class="{ active: state.detail1 }"
                @click="state.detail1 = true; state.detail2 = false; detail();">精灵名片
            </div>
            <div class="detail" :class="{ active: state.detail2 }"
                @click="state.detail2 = true; state.detail1 = false; detail();">配置参数
            </div>
        </div>
        <div class="card" v-if="state.detail1">
            <div class="c1">
                <span class="pre">名称</span>
                <div>
                    <span>{{ state.modelCard.name }}</span>
                    <span class="s">{{ state.modelCard.version }}</span>
                </div>
            </div>
            <div class="c2">
                <span class="pre">版本</span>
                <span class="s">{{ state.modelCard.version }}</span>
            </div>
            <div class="c2">
                <span class="pre">创作者</span>
                <span class="s">{{ state.modelCard.designer }}</span>
            </div>
            <div class="c2">
                <span class="pre">生成日期</span>
                <span class="s">{{ state.modelCard.generation_date }}</span>
            </div>
            <div class="c2">
                <span class="pre">升级日期</span>
                <span class="s">{{ state.modelCard.upgrade_date }}</span>
            </div>
            <div class="c3">
                <span class="pre">擅长风格</span>
                <span v-for="i in state.modelCard.style" class="s">{{ i }}</span>
            </div>
            <div class="c4">
                <span class="pre">简介</span>
                <span class="text">{{ state.modelCard.description }}</span>
            </div>
            <img :src="state.icon[11]" alt="">
            <div class="ss">代表作品</div>
        </div>
        <div class="para" v-if="state.detail2">
            <div class="sampling" v-if="state.tti">
                <span class="pre">画面大小</span>
                <select class="data" v-model="state.parameter.imageSize">
                    <option class="option" v-for="(size, index) in state.imageSizes" :key="index" :value="size">{{
                        size }}
                    </option>
                </select>
            </div>
            <div class="step" v-if="state.iti">
                <span class="pre">重绘幅度</span>
                <div class="co">
                    {{ state.parameter.repaint }}
                </div>
                <div class="slider">
                    <van-slider v-model="state.parameter.repaint" bar-height="3px" button-size="12px"
                        inactive-color="#4a4a4a" :active-color="'#33ececd8'" :max="1" step="0.5">
                        <template #button>
                            <div class="custom-button"></div>
                        </template>
                    </van-slider>
                </div>
            </div>
            <div class="step">
                <span class="pre">生成数量</span>
                <div class="co">
                    {{ state.parameter.count }}
                </div>
                <div class="slider">
                    <van-slider v-model="state.parameter.count" bar-height="3px" button-size="12px" inactive-color="#4a4a4a"
                        :active-color="'#33ececd8'" :max="20" step="1">
                        <template #button>
                            <div class="custom-button"></div>
                        </template>
                    </van-slider>
                </div>
            </div>
            <div class="sampling">
                <span class="pre">采样模式</span>
                <select class="data" v-model="state.parameter.samp">
                    <option class="option" v-for="(size, index) in state.sampling" :key="index" :value="size">{{
                        size }}
                    </option>
                </select>
            </div>
            <div class="step">
                <span class="pre">推理步数</span>
                <div class="co">
                    {{ state.parameter.step }}
                </div>
                <div class="slider">
                    <van-slider v-model="state.parameter.step" bar-height="3px" button-size="12px" inactive-color="#4a4a4a"
                        :active-color="'#33ececd8'" :max="50" step="1">
                        <template #button>
                            <div class="custom-button"></div>
                        </template>
                    </van-slider>
                </div>
            </div>
            <div class="promit">
                <span class="pre">提示词相关性</span>
                <div class="co">
                    {{ state.parameter.promit }}
                </div>
                <div class="slider">
                    <van-slider v-model="state.parameter.promit" bar-height="3px" button-size="12px"
                        inactive-color="#4a4a4a" :active-color="'#33ececd8'" :max="30" step="1">
                        <template #button>
                            <div class="custom-button"></div>
                        </template>
                    </van-slider>
                </div>
            </div>
            <div class="seed">
                <span class="pre">随机种子</span>
                <div>
                    {{ state.parameter.seed }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, onUnmounted, reactive, ref, watchEffect, inject, defineComponent } from 'vue';
// import { io } from 'socket.io-client';
import { useStore } from 'vuex';
import img1 from '../assets/列表.svg'
import img2 from '../assets/扫码.svg'
import img3 from '../assets/蓝星星棒.svg'
import img4 from '../assets/选片.svg'
import img5 from '../assets/文生.png'
import img6 from '../assets/以文生图1.svg'
import img7 from '../assets/骰子.svg'
import img8 from '../assets/加号.svg'
import img9 from '../assets/打勾选择.svg'
import img10 from '../assets/图生.png'
import img11 from '../assets/图生图9.jpeg'

import i4 from '../assets/u1147.svg'
import i1 from '../assets/u1320.svg'
import i5 from '../assets/u1336.svg'
import i6 from '../assets/u1477.svg'
import i2 from '../assets/u1482.svg'
import i3 from '../assets/u1483.svg'
import i7 from '../assets/u1440.svg'
import i0 from '../assets/u897.png'

import { io } from 'socket.io-client';
export default {
    setup() {
        const state = reactive({
            count: 0,
            tti: true,
            iti: false,
            all: true,
            selected: false,
            clickable: true,
            icon: [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img1, i0],
            detail2: false,
            detail1: true,
            allModels: [
                [
                    { id: 1, name: '萌羽儿', src: i1 },
                    { id: 2, name: '二次元模型', src: i2 },
                ],
                [
                    { id: 3, name: '二维卡通', src: i3 },
                    { id: 4, name: '幻想动漫', src: i4 },
                ],
                [
                    { id: 5, name: '二维', src: i5 },
                    { id: 6, name: '动漫', src: i6 },
                ],
                [
                    { id: 7, name: '真人写真', src: i7 },
                ]
            ],
            modelCard: {
                name: '萌羽儿',
                version: 'v1.3',
                designer: 'jevon',
                generation_date: '2023-11-30',
                upgrade_date: '2023-12-30',
                style: ['卡哇伊', '软萌', 'Q版'],
                description: '这是一段关于精灵的文字描述，简要介绍精灵的风格、能力特色以及其他的一些内内容，可能会一点长的，可能会长一点点的，可能会一点长的，可能长可能长...'
            },
            selectedModels: [

            ],
            selectedModel: '',
            sampling: [
                'UniPCMultistepScheduler',
                'DPM++ SDE Karras2',
                'DPM++ SDE Karras3',
            ],
            imageSizes: [
                '512*512 (1:1)',
                '960*540 (16:9)',
                '1024*1024 (1:1)',
                '1080*1980 (9:16)',
                '1920*1080 (16:9)',
                '2048*2048 (1:1)',
                '4096*4096 (1:1)',
            ],
            choiseText: '选择精灵',
            parameter: {
                imageSize: '960*540 (16:9)',
                repaint: 0.5,
                count: 4,
                samp: 'UniPCMultistepScheduler',
                step: 20,
                promit: 7,
                seed: 1,
            }
        });

        const handleChoise = () => {
            state.clickable = !state.clickable;
            state.choiseText = state.clickable ? '选择精灵' : '完成选择';
            if (!state.clickable)
                state.count = 0;
            if (!state.clickable) {
                state.selectedModels = [[]];
            }
            // else
            //     state.count = state.selectedModels.flat().length;
            // state.selectedModels = Array.from(state.allModels, row => []);
        };
        const handleSelectModel = (selectedName, rowIndex, index) => {
            if (state.clickable) {
                state.selectedModel = selectedName === state.selectedModel ? '' : selectedName;

            } else {
                const selectedModel = state.allModels[rowIndex][index];

                let rowWithRemovedModel = -1;
                let indexInRow = -1;

                for (let i = 0; i < state.selectedModels.length; i++) {
                    const row = state.selectedModels[i];
                    if (row.includes(selectedModel)) {
                        rowWithRemovedModel = i;
                        indexInRow = row.indexOf(selectedModel);
                        break;
                    }
                }

                if (rowWithRemovedModel !== -1) {
                    state.selectedModels[rowWithRemovedModel].splice(indexInRow, 1);

                    if (state.selectedModels[rowWithRemovedModel].length === 0) {
                        state.selectedModels.splice(rowWithRemovedModel, 1);
                    } else if (state.selectedModels[rowWithRemovedModel + 1] && state.selectedModels[rowWithRemovedModel].length < 2) {
                        state.selectedModels[rowWithRemovedModel].push(state.selectedModels[rowWithRemovedModel + 1].shift());
                        if (state.selectedModels[rowWithRemovedModel + 1].length === 0) {
                            state.selectedModels.splice(rowWithRemovedModel + 1, 1);
                        }
                    }
                } else {
                    let newRow = [selectedModel];

                    if (state.selectedModels.length === 0) {
                        state.selectedModels.push(newRow);
                    } else {
                        const lastRow = state.selectedModels[state.selectedModels.length - 1];
                        if (lastRow.length < 2) {
                            lastRow.push(selectedModel);
                        } else {
                            state.selectedModels.push(newRow);
                        }
                    }
                }
                console.log(JSON.stringify(state.selectedModels, null, 2));
            }
            state.count = state.selectedModels.flat().length;

        };
        const checkIfSelected = (name) => {
            return state.selectedModels.some(row => row.some(model => model.name === name));
        };
        const findElementPosition = (name) => {
            let rowLength = state.selectedModels[0].length;

            for (let i = 0; i < state.selectedModels.length; i++) {
                const row = state.selectedModels[i];
                const index = row.findIndex(model => model.name === name);

                if (index !== -1) {
                    return (i * rowLength) + index;
                }
            }

            return -1;
        };
        const inputText = ref('');
        const wordGroups = computed(() => {
            return inputText.value.split('\n')
                .map(line => line.trim())
                .filter(line => line !== '')

        });
        const random = ["机甲,武器", "卡通,动漫"];
        const incantation = () => {
            inputText.value = random[Math.floor(Math.random() * random.length)];
            console.log(inputText.value);
        }
        const create = () => {
            console.log(wordGroups.value);
        }

        const fileInputRef = ref(null);
        const previewImage = ref(null);
        const uploadText = ref("点击选择图片");
        const openFileInput = () => {
            if (!fileInputRef.value) {
                console.warn('The file input element is missing');
                return;
            }
            fileInputRef.value.click();
        };
        const handleFileUpload = (event) => {
            const file = event.target.files[0];
            const validFormats = ['image/jpeg', 'image/png'];

            if (file && validFormats.includes(file.type)) {
                if (file.size <= 10 * 1024 * 1024) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        previewImage.value = reader.result;
                        uploadText.value = "重新选择图片";
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert("图片大小不能超过10MB");
                }
            } else {
                alert("只支持JPG和PNG格式的图片");
            }
        };

        const clearPreview = () => {
            previewImage.value = null;
            uploadText.value = "点击选择图片";
            fileInputRef.value.value = "";
        };
        const socket = io('http://localhost:3000');
        watchEffect(() => {
            console.log('向 Socket 服务器发送消息：参数变化');
            socket.emit('message', 'para', {
                imageSize: state.parameter.imageSize,
                repaint: state.parameter.repaint,
                count: state.parameter.count,
                samp: state.parameter.samp,
                step: state.parameter.step,
                promit: state.parameter.promit,
                seed: state.parameter.seed
            });
        });

        const send = (rowIndex, index) => {
            const scrollPosition = document.getElementById("model_box").scrollTop;
            if (state.clickable) {
                console.log('向服务器发送消息：选中 ' + rowIndex + '行, ' + index + '列');
                socket.emit('message', 'selectSprite', {
                    rowIndex,
                    index,
                    scrollPosition
                });
            } else {
                console.log('向服务器发送已选模型');
                socket.emit('message', 'selectedModels', {
                    selectedModels: state.selectedModels,
                    count: state.count
                });
            }

        };
        const selected = () => {
            console.log('向服务器发送消息：切换到已选中模型');
            socket.emit('message', 'modelBtn', {
                all: state.all,
                selected: state.selected
            });
        };
        const detail = () => {
            console.log('向 Socket 服务器发送消息：切换到配置参数');
            socket.emit('message', 'detailBtn', {
                detail1: state.detail1,
                detail2: state.detail2,
                iti: state.iti
            });
        };

        onMounted(() => {
            socket.on('connect', () => {
                console.log('已连接到 Socket 服务器');
                // socket.emit('message', 'Hello,我是手机端');
                console.log('向Socket服务器发送消息：选中');
                socket.emit('message', '选中')
            });
            socket.on('response', (data) => {
                console.log('收到服务器消息:', data);
            });
        });

        onUnmounted(() => {
            socket.close();
        })

        const inputUsername = ref('');
        const usernameError = ref(false);
        const store = useStore();

        const isUsernameInputVisible = ref('');
        isUsernameInputVisible.value = store.state.isUsernameInputVisible;
        console.log(isUsernameInputVisible)
        const handleInput = () => {
            if (!/^\w{2,16}$/.test(inputUsername.value)) {
                usernameError.value = true;
            } else {
                usernameError.value = false;
            }
        };

        const submitUsername = () => {
            if (!usernameError.value) {
                store.commit('setUsername', inputUsername.value);
                isUsernameInputVisible.value = store.state.isUsernameInputVisible;
            }
        };
        return {
            state, inputText, wordGroups, create, random, incantation, handleSelectModel, send, selected, fileInputRef, uploadText,
            previewImage,
            openFileInput,
            handleFileUpload,
            clearPreview,
            handleChoise,
            checkIfSelected,
            findElementPosition,
            detail,
            inputUsername,
            usernameError,
            isUsernameInputVisible,
            handleInput,
            submitUsername,
        };
    },
};
</script>
<style scoped lang="less">
.custom-button {
    width: 12px;
    height: 12px;
    background-color: #33ececd8;
    border-radius: 50%;
}

.username-input {
    width: 318px;
    height: 208px;
    background-color: #303235;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #7d8184;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        font-size: 16px;
        font-weight: 400;
        color: #F2F2F2;
        margin-top: 30px;
    }

    input {
        margin-top: 20px;
        width: 280px;
        height: 45px;
        padding: 2px 2px 2px 2px;
        background-color: #303235;
        border: 2px inset rgba(0, 0, 0, 0);
        border-bottom: 1px solid #4A4A4A;
        outline: none;
    }

    button {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #29FFFF;
        background-color: #303235;
        outline: none;
        border: none;
    }

}

.error-msg {
    color: red;
}

.top {
    height: 40px;
    background-color: #16161A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;

    span {
        font-size: 16px;
        color: #ffffff;
    }

    img {
        width: 20px;
        height: 18px;
    }
}

.container {
    background-color: #131317;

    .btn {
        display: flex;
        padding-top: 10px;
        justify-content: center;

        .b1 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 175px;
            height: 40px;
            background-color: #000000A5;
            color: #F2F2F2A5;
            font-size: 14px;
            line-height: 40px;
            text-align: center;
            border-radius: 4px;

            img {
                width: 18px;
                height: 18px;
            }

            span {
                margin-left: 5px;
            }
        }

        .b1.active span {
            color: #F2F2F2;
        }

        .b1.active {
            background-color: #343A40;
        }
    }

    .upload_image {
        margin-top: 20px;

        .s1 {
            color: #f2f2f2d8;
            font-size: 14px;
            margin-left: 10px;
        }

        .upload-box {
            margin-top: 10px;
            margin-left: 13px;
            position: relative;
            width: 351px;
            height: 173px;
            box-sizing: border-box;
            background-color: #303235;
            border: 1px solid #414346;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 5px;

            .plus-icon {
                width: 39px;
                margin-bottom: 10px;
            }

            .upload-text {
                color: #f2f2f2d8;
                font-size: 14px;
                margin-bottom: 5px;
            }

            .upload-text2 {
                color: #f2f2f272;
                font-size: 13px;
            }


            .preview {
                position: relative;

                img {
                    width: 351px;
                    height: 177px;
                    border-radius: 5px;
                }

                .close-button {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    padding: 3px 8px;
                    background-color: #ccc;
                    color: #fff;
                    font-size: 12px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }




    }

    .create {
        .promit {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 20px;

            .s1 {
                color: #f2f2f2d8;
                font-size: 14px;
            }

            div {
                display: flex;
                align-items: center;

                img {
                    width: 15px;
                    margin-right: 2px;
                }

                .s2 {
                    font-size: 12px;
                    color: #33ecec;
                }
            }
        }

        .text {
            width: 351px;
            height: 210px;
            background-color: #303235;
            border-radius: 5px;
            margin-left: 12.5px;
            margin-top: 10px;
            border: 1px solid #494949;
            color: #d7d3d3;
            font-size: 13px;
            box-sizing: border-box;
            padding: 5px;
        }

        .text::placeholder {
            font-size: 13px;
            color: #9d9d9d;
        }

        .create_now {
            background-color: #33ecec;
            width: 351px;
            height: 56px;
            margin-left: 12.5px;
            border-radius: 5px;
            margin-top: 10px;
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .s1 {
                font-size: 18px;
                color: #333333;
                font-weight: 500;
            }

            .s2 {
                font-size: 12px;
                color: #33333372;
                font-weight: 500;
            }
        }

        .create_now:active {
            background-color: #27cbcb;
        }
    }

    .model {
        margin-top: 10px;
        width: 100%;

        span {
            color: #f2f2f2d8;
            font-size: 14px;
            margin-left: 10px;
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .choose {
                margin-top: 10px;

                .choise {
                    color: #33ecec;
                    font-size: 12px;
                    margin-left: -60px;
                }

                .choise1 {
                    color: #33ecec;
                    font-size: 12px;
                    margin-right: 10px;
                    margin-left: 5px;
                }
            }


            .btn {
                display: flex;
                justify-content: flex-start;
                margin-left: 10px;

                .all {
                    width: 91px;
                    height: 37px;
                    box-sizing: border-box;
                    background-color: #000000A5;
                    color: #F2F2F2A5;
                    font-size: 14px;
                    line-height: 37px;
                    text-align: center;
                    border-radius: 4px;
                    margin-right: 8px;

                }

                .selected {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 91px;
                    height: 37px;
                    background-color: #000000A5;
                    border-radius: 5px;
                    box-sizing: border-box;

                    .s1 {
                        color: #F2F2F2A5;
                        font-size: 14px;
                        margin-right: 8px;
                    }

                    .s2 {
                        display: inline-block;
                        width: 14px;
                        height: 14px;
                        color: #333333;
                        font-size: 10px;
                        background-color: #35ECED;
                        border-radius: 20px;
                        line-height: 14px;
                        text-align: center;
                        margin-left: -2px;
                    }
                }

                .selected.active span.s1 {
                    color: #39D1D2;
                }

                .selected.active {
                    background-color: #343A40;
                    border: 1px solid #2DA0A1;
                }

                .selected.no_click {
                    background-color: #A4A4A4;

                }

                .selected.no_click span.s1 {
                    color: #9E9E9E;
                }

                .all.active {
                    background-color: #343A40;
                    color: #39D1D2;
                    border: 1px solid #2DA0A1;
                }
            }

        }




        .model_box {
            margin-top: 6px;
            height: 415px;
            overflow-y: auto;
            scrollbar-width: thin;

            .row {
                margin-left: 8px;
                // margin-right: -20px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                div {
                    box-sizing: border-box;
                    margin-bottom: 6px;
                    margin-right: 6px;
                    position: relative;
                    width: 175px;
                    height: 132px;
                    border-radius: 5px;

                    img {
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                        border: 2px solid transparent;
                    }

                    .over {
                        z-index: 1;
                        position: absolute;
                        width: 100%;
                        height: 22px;
                        bottom: -7.5px;
                        left: 2px;
                        font-size: 12px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 22px;
                        border-radius: 1px;
                        background-color: rgba(60, 67, 73, 0.5);
                        border-bottom-left-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }

                    .over {
                        z-index: 1;
                        position: absolute;
                        width: 100%;
                        height: 22px;
                        bottom: -7.5px;
                        left: 2px;
                        font-size: 12px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 22px;
                        border-radius: 1px;
                        background-color: rgba(60, 67, 73, 0.5);
                        border-bottom-left-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }

                    .over2 {
                        z-index: 1;
                        position: absolute;
                        height: 17px;
                        width: 17px;
                        top: 8px;
                        right: 6px;
                        border-radius: 50%;
                        background-color: #B9BEC2;
                    }

                    .over3 {
                        z-index: 1;
                        position: absolute;
                        height: 20px;
                        width: 20px;
                        top: 8px;
                        right: 0px;
                        border-radius: 50%;
                        line-height: 20px;
                        text-align: center;
                        background-color: #31ECEC;
                        color: #333333;
                        font-size: 14px;
                    }
                }

                .selected {
                    border-radius: 7px;
                    border-color: #35ECED;
                }
            }
        }

        .model_box::-webkit-scrollbar {
            width: 4px;
        }

        .model_box::-webkit-scrollbar-thumb {
            background-color: #303235;
            border-radius: 5px;
            height: 6px;
        }
    }

    .btn2 {
        margin-left: 10px;
        margin-top: 40px;
        display: flex;

        .detail {
            width: 91px;
            height: 37px;
            box-sizing: border-box;
            background-color: #000000A5;
            color: #F2F2F2A5;
            font-size: 14px;
            line-height: 37px;
            text-align: center;
            border-radius: 4px;
            margin-right: 8px;
        }

        .detail.active {
            background-color: #343A40;
            color: #39D1D2;
            border: 1px solid #2DA0A1;
        }

    }

    .card {
        margin-left: 10px;
        margin-top: 15px;
        height: 494px;

        .c1 {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #F2F2F272;
            margin-bottom: 7px;

            .pre {
                margin-right: 40px;
            }

            div {
                span {
                    font-size: 12px;
                    color: #F2F2F2D8;
                    margin-right: 5px;
                }

                .s {
                    font-size: 10px;
                    color: rgba(215, 212, 212, 0.8);
                    background-color: #2E3B84;
                    border-radius: 4px;
                    padding-left: 3px;
                    padding-right: 3px;
                    padding-top: 1px;
                    padding-bottom: 1px;
                }
            }
        }

        .c2 {
            margin-bottom: 7px;

            .pre {
                font-size: 12px;
                color: #F2F2F272;
                display: inline-block;
                width: 64px;
            }

            .s {
                font-size: 12px;
                color: #F2F2F2D8;
            }
        }

        .c3 {
            margin-bottom: 7px;

            .pre {
                font-size: 12px;
                color: #F2F2F272;
                display: inline-block;
                width: 64px;
            }

            .s {
                font-size: 10px;
                color: rgba(186, 145, 236, 0.9);
                background-color: #472478;
                border-radius: 4px;
                padding-left: 4px;
                padding-right: 4px;
                padding-top: 1px;
                padding-bottom: 1px;
                margin-right: 4px;
            }
        }

        .c4 {
            display: flex;
            flex-wrap: wrap;

            .pre {
                margin-top: 5px;
                font-size: 12px;
                color: #F2F2F272;
                display: block;
            }

            .text {
                line-height: 20px;
                font-size: 12px;
                color: #F2F2F2D8;
                display: block;
                margin-left: 62px;
                margin-top: -17px;
                margin-right: 20px;

            }
        }

        img {
            width: 291px;
            height: 218px;
            margin-left: 37px;
            margin-top: 10px;
        }

        .ss {
            color: #F2F2F272;
            font-size: 12px;
            width: 48px;
            margin-left: 158.5px;

        }
    }

    .para {
        margin-top: 20px;
        margin-left: 10px;
        height: 494px;

        .sampling {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .pre {
                font-size: 12px;
                color: #f2f2f2a5;
            }

            .data {
                width: 275px;
                height: 32px;
                background-color: #303235;
                margin-right: 10px;
                border-radius: 5px;
                color: #f2f2f2fa;
                font-size: 12px;
                border: 1px solid #4A4A4A;

            }
        }

        .step {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .pre {
                font-size: 12px;
                color: #f2f2f2a5;
            }

            .slider {
                width: 217px;
            }

            .co {
                width: 34px;
                height: 30px;
                background-color: #343a40;
                text-align: center;
                line-height: 30px;
                margin-left: 32px;
                margin-right: 20px;
                border-radius: 5px;
                font-size: 12px;
                color: #f2f2f2;
                border: 1px solid #4A4A4A;
            }

        }

        .promit {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .pre {
                font-size: 12px;
                color: #f2f2f2a5;
            }

            .slider {
                width: 217px;
            }

            .co {
                width: 34px;
                height: 30px;
                background-color: #343a40;
                text-align: center;
                line-height: 30px;
                margin-left: 8px;
                margin-right: 20px;
                border-radius: 5px;
                font-size: 12px;
                color: #f2f2f2;
                border: 1px solid #4A4A4A;
            }

        }

        .seed {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .pre {
                font-size: 12px;
                color: #f2f2f2a5;
            }

            div {
                width: 273px;
                height: 30px;
                background-color: #303235;
                margin-right: 10px;
                border-radius: 5px;
                border: 1px solid #4A4A4A;
                font-size: 12px;
                color: #f2f2f2fa;
                text-align: center;
                line-height: 30px;
            }
        }
    }
}
</style>
