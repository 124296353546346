import { createStore } from 'vuex'

export default createStore({
  state: {
    username: '',
    isUsernameInputVisible: true
  },
  getters: {
  },
  mutations: {
    setUsername(state, username) {
      state.username = username;
      state.isUsernameInputVisible = false;
      localStorage.setItem('username', username);
    },
    showUsernameInput(state) {
      state.isUsernameInputVisible = true;
    }
  },
  actions: {
    checkFirstVisit({ commit }) {
      if (!localStorage.getItem('username')) {
        commit('showUsernameInput');
      }
    }
  },
  modules: {
  }
})
